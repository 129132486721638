html {
  scroll-behavior: smooth;
  font-family: 'DM Sans', sans-serif;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* .swiper {
  width: 100%;
  height: 100%;
} */

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.autoplay-progress {
  position: absolute;
  right: 16px;
  bottom: 16px;
  z-index: 10;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: var(--swiper-theme-color);
}

.autoplay-progress svg {
  --progress: 0;
  position: absolute;
  left: 0;
  top: 0px;
  z-index: 10;
  width: 100%;
  height: 100%;
  stroke-width: 4px;
  stroke: var(--swiper-theme-color);
  fill: none;
  stroke-dashoffset: calc(125.6 * (1 - var(--progress)));
  stroke-dasharray: 125.6;
  transform: rotate(-90deg);
}


  .popupnav{
    transition-timing-function: ease-in-out;
    transition: 2s ease-in-out;
  }
  
  
  .mobilebar {
    width: 80%;
    height: 100%;
    position: fixed;
    top: 0;
    left: -1000px;
    background-color: transparent;
    transition: left 0.7s ease-in-out;
  }
  
  .mobilebar.visible {
    left: 0;
  }

  .parallax_cover img {
    object-fit: cover;
    height: 100% !important;
    width: 100% !important;
  }
  .borderclass{
    border-bottom: 1px solid lightgray;
    width: 200px;
  }
  .react-parallax-bgimage{
    top:50px !important;
  }
  .react-parallax-content {
    position: static !important;
  }